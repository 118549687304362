import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Notarkosten = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Alles Wissenswerte zu Notarkosten und wie man dabei sparen kann" showCalc={false} />
            <Article>
                <p>
                    Immer wenn eine Immobilie den Eigentümer wechselt, braucht es einen Notar. Notare kümmern sich zum
                    Beispiel um die Eintragung ins{" "}
                    <Link to="/artikel/grundbuch-kosten/" target="_blank" rel="noreferrer noopener">
                        Grundbuch
                    </Link>
                    , ohne die ein Verkauf bzw. Kauf nicht rechtskräftig wäre. Für die Erfüllung solcher Aufgaben
                    bekommen sie ein Honorar, das vom Wert der Immobilie abhängt. Diese Kosten sind vom Käufer bzw. der
                    Käuferin einer Immobilie zu tragen. Wenn du also planst, eine Immobilie zu kaufen, solltest du dich
                    im Vorhinein über die Notarkosten informieren, um dein Budget richtig einschätzen zu können. In
                    diesem Artikel möchten wir einen Überblick dazu geben.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Notarkosten</h2>
                <p>
                    Die Notarkosten gehören zu den grundsätzlichen{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        Kaufnebenkosten
                    </Link>{" "}
                    eines jeden Immobilienkaufs. Die Höhe hängt vom Immobilienpreis, aber auch von den notariellen
                    Leistungen ab, die du in Anspruch nimmst. Dabei kannst du dich grundsätzlich an ca. 3% des
                    Kaufpreises orientieren. Eines vorweg: in manchen Fällen kann man zwar sparen, aber du kommst um die
                    Notarkosten im Endeffekt nicht herum. Außerdem wird immer der aktuelle Umsatzsteuersatz auf die
                    Notarkosten aufgerechnet. Die Preise für notarielle Leistungen sind gesetzlich durch das
                    Notariatstarifgesetz geregelt, dabei aber vor allem die Preisobergrenzen. Das bedeutet also, dass es
                    durchaus noch Unterschiede bei den Honoraren geben kann, die verschiedene Notare und Notarinnen
                    verlangen. Es lohnt sich immer, vorher mehrere Angebote miteinander zu vergleichen.
                </p>
                <hr />

                <h2>Was macht ein Notar oder eine Notarin?</h2>
                <p>
                    Sobald eine Immobilie verkauft wird, muss der neue Eigentümer bzw. die neue Eigentümerin ins
                    Grundbuch eingetragen werden. Das wird vom Notar übernommen. Er sorgt dafür, dass die Abwicklung des
                    Kaufs rechtmäßig abläuft, kann aber auch eine parteilose beratende Funktion einnehmen. Mehrere
                    Aufgaben werden von einem Notar oder einer Notarin erfüllt:
                </p>
                <ul>
                    <li>Sie erstellen den Kaufvertrag</li>
                    <li>Beglaubigen diesen Kaufvertrag</li>
                    <li>Wickeln die Eintragung ins Grundbuch ab</li>
                    <li>Und machen auch Treuhandabwicklungen</li>
                    <li>Sie beraten bei allen rechtlichen Fragen rund um den Immobilienkauf</li>
                </ul>
                <p>
                    Spätestens bei der Beglaubigung der Unterschriften der Kaufverträge müsst ihr einen Notar dazuholen.
                    Das ist der Fall, weil das Gesetz eine öffentliche Beurkundung verlangt, die bloß der Notar
                    durchführen kann.
                </p>
                <p>
                    Auch wenn du einen Hypothekarkredit verwendest, um deine Immobilie zu finanzieren, brauchst du die
                    Leistungen eines Notars für die Grundbucheintragung. Er beglaubigt bei der Eintragung des
                    Pfandrechts im Grundbuch sowohl deine Unterschrift als Kreditnehmer*in, als auch die der
                    finanzierenden Bank.
                </p>
                <p>
                    Oft übernimmt ein Notar oder eine Notarin auch noch andere Dinge. Sie prüfen zum Beispiel, ob die
                    Verkäufer wirklich Eigentümer der Immobilie sind und auch, ob diese bereits durch eine Hypothek
                    belastet ist. Außerdem informieren sie das Finanzamt über den Kauf und kontrollieren auch die
                    pünktliche und vollständige Überweisung des Geldes.
                </p>
                <p>
                    Schon vor der Abwicklung des Kaufvertrags einigt man sich darauf, wer die Notarkosten tragen soll.
                    Meistens macht das der Käufer oder die Käuferin.
                </p>
                <hr />

                <h2>Konkrete Zahlen: Notarkosten in Österreich</h2>
                <p>
                    Das Honorar von Notaren ist in Österreich durch das Notariatstarifgesetz geregelt. Darin wird
                    zwischen mehreren Gebühren unterschieden:
                </p>
                <ul>
                    <li>Wertgebühren,</li>
                    <li>Zeitgebühren,</li>
                    <li>Abschriftenbeglaubigungsgebühren,</li>
                    <li>Entfernungsgebühren und</li>
                    <li>Kanzleigebühren</li>
                </ul>
                <p>
                    Je nachdem, wieviel deine Immobilie Wert ist, betragen die Notarkosten zwischen einem und drei
                    Prozent des Kaufpreises. Es ist immer ratsam, sich in einem Erstgespräch mit dem Notar oder der
                    Notarin, einen Überblick über die erwarteten Kosten geben zu lassen. Für das Erstgespräch dürfen
                    keine Gebühren verrechnet werden.
                </p>
                <hr />

                <h2>Wo kann ich sparen?</h2>
                <p>
                    So viel vorweg: „Sich den Notar sparen“ ist nie eine gute Idee. Denn ohne beglaubigten Vertrag und
                    ohne Grundbucheintrag bist du nicht rechtskräftiger Grundstückseigentümer. Aber man kann zum
                    Beispiel sparen, indem man zuerst ein Grundstück kauft und erst später darauf baut. Unbebaute
                    Grundstücke sind günstiger und die Notarkosten leiten sich ja von den Kosten des Grundstücks bzw.
                    der Immobilie ab. Alles was danach passiert, zählt nicht mehr zum Immobilienkauf. Auch die
                    Erschließungskosten wie für Gas, Wasser, Strom usw. sind im Kaufpreis enthalten, wenn du ein bereits
                    bebautes Grundstück kaufst.
                </p>
                <p>
                    Je günstiger der Kaufpreis, desto günstiger der Kredit. Mit unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    kannst du dir sofort anschauen, welche Summen für dich in Frage kommen und welche Option die beste
                    ist.
                </p>
                <p>
                    Von der Steuer sind Notarkosten übrigens nicht absetzbar, wenn es um privates Wohnen geht. Weder
                    beim Wohnungskauf, noch beim Kauf eines Grundstücks. Eine einzige Ausnahme gibt es, wenn du planst,
                    deine gekaufte Immobilie in Zukunft zu vermieten und das auch schon durch einen unterschriebenen
                    Vertrag nachweisen kannst. Dabei handelt es sich dann bei deinem Kauf nicht um das Ziel, darin
                    privat zu wohnen. Hier solltest du aber mit deinem Steuerberater sprechen, was dabei zu beachten
                    ist.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Lass dich online beraten, sprich mit unseren Finanzierungsexperten
                    </a>
                </p>
                <hr />

                <h2>Was ist der Unterschied zwischen Notar und Anwalt?</h2>
                <p>
                    Notare und Anwälte haben zwar dieselbe Ausbildung, aber der Notar darf im Unterschied zu Anwälten
                    keine Partei ergreifen. Notare sind unparteiische Träger eines öffentlichen Amtes. Sie sind zur
                    neutralen Beratung alles beteiligten Personen verpflichtet. Sie führen aber auch Rechtsberatungen
                    durch!
                </p>
                <hr />

                <h2>Wo finde ich einen Notar?</h2>
                <p>
                    In Österreich gibt es mehrere hundert Notare und Notarinnen. Höre dich am besten in deinem
                    Bekanntenkreis um – vielleicht hat jemand Erfahrungen und einen guten Tipp. Auch Immobilienmakler
                    sind gut vernetzt und können dir bei der Suche helfen. Außerdem kann man sich an die österreichische
                    Notariatskammer wenden, die dir jemanden vermitteln kann. Ganz grundsätzlich schadet es nicht, dich
                    überall umzuhören.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"notarkosten"}></BreadcrumbList>
            <ArticleStructuredData page={"notarkosten"} heading={"Negativzins – Why so negative?"} />
        </Layout>
    );
};

export default Notarkosten;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.notarkosten", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
